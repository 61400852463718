import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../components/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ButtonContent = () => {
  const { t } = useTranslation()

  return (
    <>
      <span>{t('more')}</span>
    </>
  )
}

const LoadMoreButton = ({ loading, ...args }) => {
  if (loading) {
    return (
      <Button disabled className="opacity-50 cursor-not-allowed	" {...args}>
        <ButtonContent />
        <FontAwesomeIcon
          className="fa-spin-pulse"
          icon={['fas', 'fa-spinner']}
        />
      </Button>
    )
  } else {
    return (
      <Button {...args}>
        <ButtonContent />
      </Button>
    )
  }
}

export default LoadMoreButton
