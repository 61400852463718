import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, withPrefix } from 'gatsby'
import { graphql } from 'gatsby'
import DefaultLayout from '../layouts/DefaultLayout'
import DataList from '../components/DataList'
import LoadMoreButton from '../common/LoadMoreButton'

const ResourcesPage = ({ data }) => {
  const { t } = useTranslation()
  const { paginatedCollectionPage } = data
  const { nodes } = paginatedCollectionPage

  const [loading, setLoading] = useState(false)
  const [latestPage, setLatestPage] = useState(paginatedCollectionPage)
  const [posts, setPosts] = useState(nodes)

  const handlePagination = async (e) => {
    e.preventDefault()
    setLoading(true)

    const collectionId = latestPage.collection.id
    const nextPageId = latestPage.nextPage.id
    const url = withPrefix(`/pagination/${collectionId}/${nextPageId}.json`)

    const data = await fetch(url).then((response) => response.json())

    setPosts((posts) => [...posts, ...data.nodes])

    setLatestPage(data)
    setLoading(false)
  }

  return (
    <DefaultLayout>
      <DataList
        className="my-24"
        data={posts}
        titleComponent={() => {
          return (
            <h3 className="flex justify-between text-green-theme font-bold text-2xl xl:text-4xl">
              <span>{t('resources')}</span>
            </h3>
          )
        }}
        articleTemplate={({ title, link }) => {
          return (
            <h2 className="hover:underline hover:text-green-theme text-xl xl:text-2xl font-bold">
              <a href={link}>{title}</a>
            </h2>
          )
        }}
      />
      {latestPage.hasNextPage && (
        <div className="flex justify-center">
          <LoadMoreButton onClick={handlePagination} loading={loading} />
        </div>
      )}
    </DefaultLayout>
  )
}

export const query = graphql`
  query getResourcesPaginatedCollectionPage($collection: String) {
    paginatedCollectionPage(
      collection: { name: { eq: $collection } }
      index: { eq: 0 }
    ) {
      nodes
      hasNextPage
      nextPage {
        id
      }
      collection {
        id
      }
    }
  }
`

export default ResourcesPage
